import { DatePipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  faFileExcel,
  faPenToSquare,
  faRectangleList,
  faTrashCan,
} from '@fortawesome/free-regular-svg-icons';
import { faChevronLeft, faPrint } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import {
  OccEndpointsService,
  RoutingService,
  TranslationService,
} from '@spartacus/core';
import { Subscription } from 'rxjs';
import * as _ from 'underscore';
import { CheckoutService } from '../../shared/modules/checkout/checkout.service';
import { CustomAddToCartService } from '../custom-add-to-cart.service';
import { ProductDetailsComponent } from '../custom-add-to-cart/product-details/product-details.component';

@Component({
  selector: 'app-standard-cart-confirmation',
  templateUrl: './standard-cart-confirmation.component.html',
})
export class StandardCartConfirmationComponent implements OnInit, OnDestroy {
  @ViewChild('p2') public savePopover: NgbPopover;
  orderConfirmationDetails: any;
  screenwidth: any;
  productEntries: any = [];
  estimatedDate: any;
  baseUrl: string;
  fallbackImage = '../../assets/i18n-assets/sanofishoppe_missing_product.png';
  public images: any;
  getuserData: any;
  selectedData: any;
  saveCartName: string;
  errorMsg: string;
  cartId: string;
  showCard: any;
  cardTrans: any;
  poNumber: any;
  orderDetailsSub: Subscription;
  invalidNameSub: Subscription;
  saveOrderSub: Subscription;
  totalPrice: string;
  combinedOrders: any = [];
  orders: any = [];

  // icons
  faPrint = faPrint;
  faFileExcel = faFileExcel;
  faTrashCan = faTrashCan;
  faRectangleList = faRectangleList;
  faChevronLeft = faChevronLeft;
  faPenToSquare = faPenToSquare;
  dateFormat = 'MM/dd/yyyy';
  orderMessage: string;
  constructor(
    private readonly checkoutService: CheckoutService,
    private readonly router: RoutingService,
    private readonly occEndPoint: OccEndpointsService,
    private readonly addToCart: CustomAddToCartService,
    private readonly cdr: ChangeDetectorRef,
    public datepipe: DatePipe,
    private readonly modalService: NgbModal,
    private readonly translation: TranslationService
  ) {
    this.screenwidth = window.screen.width;
  }

  ngOnInit(): void {
    this.baseUrl = this.occEndPoint.getBaseUrl({
      baseUrl: true,
      prefix: false,
      baseSite: false,
    });
    this.translation.translate('addToCartItems.card').subscribe((res) => {
      this.cardTrans = res;
    });
    this.orderDetailsSub =
      this.checkoutService.updatedStdOrderDetails$.subscribe((res) => {
        if (res) {
          this.orders = res?.orders;
          this.orderMessage = res?.message;
          this.totalPrice = res?.totalPrice?.value;
          this.orderConfirmationDetails = res?.orders[0];
          this.validatePONumber(
            this.orderConfirmationDetails?.purchaseOrderNumber
          );
          this.productEntries = [];
          this.saveCartName =
            this.orderConfirmationDetails?.shippingAddress?.companyName?.slice(
              0,
              30
            );
          for (let entries of this.orderConfirmationDetails.entries) {
            this.estimatedDate = entries?.estimatedDeliveryDate;
            this.productEntries.push(entries);
          }
          const firstOrder = this.orders[0].entries;
          const secondOrder = this.orders[1]?.entries;
          if (this.orders?.length > 1) {
            this.combinedOrders = [
              ...firstOrder,
              { multi: true },
              ...secondOrder,
            ];
          } else {
            this.combinedOrders = [...firstOrder];
          }
          for (const entry of this.combinedOrders) {
            const imagesFormat = _.clone(
              _.findWhere(entry.product?.images, { format: 'thumbnail' })
            );
            if (imagesFormat) {
              imagesFormat.url = `${this.baseUrl}${imagesFormat?.url}`;
              entry.PRIMARY = imagesFormat;
            }
          }
          this.showCard = '';
          if (this.orderConfirmationDetails.paymentInfo) {
            this.showCard =
              this.orderConfirmationDetails?.paymentInfo?.cardNumber.substring(
                6,
                10
              );
          }
        }
      });
    this.cdr.detectChanges();
  }
  billingAccount(): void {
    this.router.goByUrl('/my-account/profile');
    this.checkoutService.tabSelection('Account information');
  }
  toggle(popover) {
    if (popover.isOpen()) {
      popover.close();
    } else {
      popover.open();
    }
    this.errorMsg = '';
    this.saveCartName =
      this.orderConfirmationDetails?.shippingAddress?.companyName?.slice(0, 30);
  }

  saveOrder(): void {
    const reg = /^[^`!@#~$%\^&*()_+\-={};':"\\|[\]\\.,<>/\/?~]*$/;
    if (this.saveCartName && RegExp(reg).test(this.saveCartName)) {
      this.addToCart.saveOrderDetails(
        this.saveCartName,
        this.orderConfirmationDetails?.code
      );
      this.invalidNameSub = this.addToCart.invalidOrderName$.subscribe(
        (res) => {
          if (res) {
            for (const error of res?.error?.errors) {
              this.errorMsg = error?.message;
            }
          }
        }
      );
      this.saveOrderSub = this.addToCart.saveOrderSuccess$.subscribe((res) => {
        if (res) {
          if (this.savePopover) {
            this.savePopover.close();
          }
          this.errorMsg = '';
          this.saveCartName =
            this.orderConfirmationDetails?.shippingAddress?.companyName?.slice(
              0,
              30
            );
        }
      });
    } else {
      const messageValid = 'Save cart name is not valid.';
      const messageInvalid = 'Cart name cannot be blank';
      if (this.saveCartName) {
        this.errorMsg = messageValid;
      } else {
        this.errorMsg = messageInvalid;
      }
    }
  }
  exportXls() {
    const cartProducts = [];
    for (const entries of this.productEntries) {
      cartProducts.push({
        Customer: this.orderConfirmationDetails?.soldtoAddress?.unitID,
        'Material ID': entries?.product?.code,
        Quantity: entries?.quantity,
        'PO Number':
          this.orderConfirmationDetails?.purchaseOrderNumber === ''
            ? `WWW:${this.datepipe.transform(
                this.orderConfirmationDetails?.created,
                'dd/MM/yyyy'
              )}`
            : this.orderConfirmationDetails?.purchaseOrderNumber,
      });
    }
    this.addToCart.downloadXls(cartProducts, 'Order confirmation');
  }
  omit_special_char(event) {
    this.errorMsg = '';
    const reg = /^[^`!@#~$%\^&*()_+\-={};':"\\|[\]\\.,<>/\/?~]*$/;
    return new RegExp(reg).test(event.key);
  }
  productDetails(productDetails): void {
    const modalRef = this.modalService.open(ProductDetailsComponent, {
      windowClass: 'product-details-modal',
    });
    modalRef.componentInstance.productDetails = productDetails;
    modalRef.componentInstance.standardCartCode = '';
    modalRef.componentInstance.unitSection = 'NoUnits';
  }

  validatePONumber(purchaseOrderNumber: any) {
    if (purchaseOrderNumber) {
      const searchString = 'WWW:';
      const regex = new RegExp(searchString, 'i');
      if (regex.test(purchaseOrderNumber)) {
        this.poNumber = '';
      } else {
        this.poNumber = purchaseOrderNumber;
      }
    } else {
      this.poNumber = '';
    }
  }

  ngOnDestroy() {
    this.saveOrderSub?.unsubscribe();
    this.invalidNameSub?.unsubscribe();
    this.orderDetailsSub?.unsubscribe();
  }
}
