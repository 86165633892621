<cx-searchbox [quickOrder]='true' [searchValueClear]="clearSearchValue" (selectedProductEvent)="onProductSelected($event)" [config]='QUICKORDER_SEARCH_BOX_CONFIG'></cx-searchbox>

<div *ngIf="quickOrderDetail" class="my-4">
    <div *ngIf="!productInfo.flu" class="unit-calc">
        <div class="d-flex justify-content-around w-100 mb-4">
            <div class="price-details">
                <div class="mb-2 text-fixed-legal-copy color-grey-dark font-weight-bold"
                     *ngIf="productInfo?.actualPotency else notActualPotency">
                {{'plp.unitPrice'|cxTranslate}}
                </div>
                <ng-template #notActualPotency>
                    <div class="mb-2 text-fixed-legal-copy color-grey-dark font-weight-bold">{{'plp.yourPrice'|cxTranslate}}</div>
                </ng-template>
                <div class="c-heading c-heading-h3">
                    <span *ngIf="priceResAwait else priceReceived">
                        <i class="fa fa-spinner fa-spin" *ngIf="priceResAwait"></i>
                    </span>
                    <ng-template #priceReceived>
                        <span *ngIf="productInfo?.price == undefined || productInfo?.price?.value === undefined">-</span>
                        <span *ngIf="productInfo?.price && productInfo?.price?.value !== undefined">
                            <span>{{productInfo?.price?.value | currency}}</span>
                        </span>
                    </ng-template>
                </div>
            </div>

            <div class="units" *ngIf="!productInfo?.discontinued && productInfo?.available">
                <div class="mb-2 text-center font-weight-bold color-grey-dark">
                    {{'plp.units' | cxTranslate}}
                </div>
                <div class="d-flex justify-content-center align-items-center">
                    <span id="{{'unitMinus'+productInfo?.code+'q'}}" class="d-none"></span>
                    <span id="{{'unitMinusDisable'+productInfo?.code+'q'}}" class="d-none"></span>
                    <span id="{{'unitPlus'+productInfo?.code+'q'}}" class="d-none"></span>
                    <span id="{{'unitPlusDisable'+productInfo?.code+'q'}}" class="d-none"></span>

                    <button
                        class="c-count-change-btn disabled"
                        id="{{'minusIcon'+productInfo.code+'q'}}"
                        aria-hidden="true"
                        (click)="performQuantityComputation(2,productInfo.code+'q',productInfo.quantityPerUnit)"
                    >
                        <i class="fa fa-minus" aria-hidden="true"></i>
                    </button>
                    <input
                        type="number"
                        class="prod-quantity text-center"
                        value='1'
                        (keyup)="performQuantityComputation(null,productInfo.code+'q',productInfo.quantityPerUnit)"
                        onkeypress="return /[0-9a-zA-Z]/i.test(event.key)"
                        id="{{'prodQuantity'+productInfo.code+'q'}}"
                        maxlength="4"
                    />
                    <button
                        class="c-count-change-btn"
                        aria-hidden="true"
                        id="{{'plusIcon'+productInfo.code+'q'}}"
                        (click)="performQuantityComputation(1,productInfo.code+'q',productInfo.quantityPerUnit)"
                    >
                        <i class="fa fa-plus" aria-hidden="true"></i>
                    </button>
                </div>
            </div>
        </div>

        <button type="button" class="c-button c-button--primary quickorder-button" [disabled]="priceResAwait" *ngIf="!productInfo?.discontinued && productInfo?.available && !(productInfo?.stockAvailableStatus == 'LIMITED' && productInfo?.allowLimitedStockProducts !== true)" [ngbPopover]=popTitleSuccess placement="bottom" triggers="manual" #p2="ngbPopover" popoverClass="savesuccess-msg" [closeDelay]="500" (click)="addToCart(productInfo?.code+'q',productInfo?.code)">

            <ng-template #popTitleSuccess>
                <div class="cart-value-added">
                    <img src="../../../assets/icons/confirmation-positive-green.png" class="positive-green-icon" alt="suc">
                    <span class="confirmation-itemsadded">{{itemAddedQuantity}} {{'addToCartItems.addEntrySuccess'|cxTranslate}}</span>
                </div>
            </ng-template>
            <i *ngIf="buttonName === 'Reserve now'" class="fa fa-calendar" aria-hidden="true"></i>
            {{buttonName}}
        </button>
        <section *ngIf="!productInfo?.discontinued && productInfo?.available">
            <section *ngIf="productInfo.sapAllocation">
                <b class="prod-allocation">{{'plp.productAllocation' | cxTranslate}}</b>
                <p class="allocation-p">{{'plp.allocationDetails' | cxTranslate}}
            </section>
        </section>
    </div>
    <section *ngIf="productInfo?.discontinued || !productInfo?.available">
        <section *ngIf="productInfo?.discontinued && productInfo?.available">
            <p class="product-discontinued-txt1">
                {{'plp.discontinued' | cxTranslate}}</p>
        </section>
        <section *ngIf="productInfo?.discontinued && !productInfo?.available">
            <p class="product-discontinued-txt1">
                {{'plp.discontinued' | cxTranslate}}</p>
        </section>
        <section *ngIf="!productInfo.discontinued && !productInfo?.available" class="m0 left-align">
            <b class="prod-allocation class-avail" *ngIf="productInfo?.availabilityText">{{productInfo?.availabilityText}}</b>
            <b class="prod-allocation class-avail" *ngIf="!productInfo?.availabilityText">{{'plp.noavailabilityText' | cxTranslate}}</b>
        </section>
    </section>

    <div *ngIf="productInfo.flu && productInfo.available && !productInfo.discontinued">
        <button
            type="button"
            class="c-button c-button--secondary w-100"
            (click)="navToReservenow()"
        >
            <i *ngIf="buttonName === 'Reserve now'" class="fa fa-calendar" aria-hidden="true"></i>
            {{'plp.reserve'|cxTranslate}}
        </button>
    </div>
    <div *ngIf="
         (!productInfo?.price ||
         productInfo?.price?.value == undefined ||
         productInfo?.price?.value == 0 ||
         productInfo?.price?.value == 0.0 ||
         (productInfo?.stockAvailableStatus == 'LIMITED' && productInfo?.allowLimitedStockProducts !== true)) && !priceResAwait ">
        <button
                type="button"
                class="c-button c-button--primary w-100"
                disabled
        >
            {{ "plp.unavailableToOrder" | cxTranslate }}
        </button>
    </div>
</div>
