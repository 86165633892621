<ng-container *ngIf="isUserLoggedIn">
  <form>
    <label class="searchbox" [class.dirty]="!!searchInput.value">
      <input
        #searchInput
        [(ngModel)]="searchValue"
        [ngModelOptions]="{ standalone: true }"
        placeholder="{{
          quickOrder
            ? 'Search for Product'
            : 'Search for Product, Product #, Help'
        }}"
        (click)="open()"
        (focus)="open()"
        (input)="search(searchInput.value)"
        (keydown.escape)="onBlur()"
        (keydown.enter)="
          close($event, true);
          launchSearchResult($event, searchInput.value);
          updateChosenWord(searchInput.value)
        "
        value="{{ chosenWord }}"
        role="presentation"
      />

      <button
        role="presentation"
        class="search-icon"
        [disabled]="searchInput.value.length === 0"
      >
        <cx-icon
          [type]="iconTypes.SEARCH"
          (click)="launchSearchResult($event, searchInput.value)"
        ></cx-icon>
      </button>
    </label>
  </form>
  <!-- Products -->
  <div
    *ngIf="closeDropDown && !quickOrder && results$ | async as result"
    class="results"
    id="results"
    role="listbox"
  >
    <ul
      class="products"
      *ngIf="result.products && result.products.length > 0"
      tabindex="0"
    >
      <li class="drop-down-header">
        <span class="drop-down-title">
          {{ 'searchProduct.matchingProducts' | cxTranslate }}
        </span>
        <a
          class="push-right all-results-link"
          (click)="launchSearchResult($event, searchInput.value)"
          >{{ 'searchProduct.seeAll' | cxTranslate }}</a
        >
      </li>
      <li *ngFor="let product of result.products">
        <a
          [class.has-media]="config.displayProductImages"
          [routerLink]="['/product', product.code, product.code]"
          (blur)="close($event)"
          (click)="close($event, true)"
        >
          <cx-media
            *ngIf="config.displayProductImages && product?.images"
            [container]="product?.images?.PRIMARY"
            format="thumbnail "
            role="presentation "
            class=""
          ></cx-media>
          <img
            *ngIf="config.displayProductImages && product?.images === undefined"
            [src]="fallbackImage"
            format="thumbnail "
            role="presentation "
            class="thumbnail-img"
            alt="defImg"
          />
          <div class="name" [innerHTML]="product.nameHtml"></div>
        </a>
      </li>
      <li></li>
    </ul>
  </div>
  <!-- FAQ -->
  <div
    *ngIf="
      closeDropDown &&
      !quickOrder &&
      helpResults &&
      helpResults?.content.length > 0
    "
    class="c-lists"
  >
    <ul class="c-list">
      <li class="drop-down-header searchbox-heading">Help Center results</li>
      <li *ngFor="let help of helpResults?.content">
        <i class="fa-regular fa-bookmark"></i
        ><a
          class="c-link c-link-wrap"
          (click)="
            goToHelp($event, help?.code); updateChosenWord(searchInput.value)
          "
          >{{ help?.question }}</a
        >
      </li>
    </ul>
  </div>
  <!-- No Results -->
  <div
    class="c-lists message"
    *ngIf="
      closeDropDown &&
      helpResults &&
      !helpResults?.content.length > 0 &&
      searchValue &&
      !quickOrder &&
      faqShow
    "
  >
    <p>
      Sorry your search for
      <span class="font-bold">{{ searchInput.value }}</span> did not match any
      <span class="c-link" [routerLink]="['/Open-Catalogue/c/1']"
        >products.</span
      >
    </p>
    <p class="mb-0">
      Find fast answers to common questions about how SanofiShop works and how
      it can benefit your practice, in the
      <span class="c-link" [routerLink]="['/faq']">Help Center</span>
    </p>
  </div>
  <!-- RECENT -->
  <div
    *ngIf="
      closeDropDown &&
      hideSearchHistory &&
      faqShow &&
      !helpResults?.content.length > 0 &&
      !quickOrder &&
      recentUserSearch
    "
    role="listbox"
    class="searchHistory c-lists"
    id="searchHistory"
  >
    <ul
      class="products resetListStyle c-list"
      tabindex="0"
      *ngIf="recentUserSearch.recentSearchText"
    >
      <li class="drop-down-header searchbox-heading">
        {{ 'searchProduct.recentSearches' | cxTranslate }}
      </li>
      <li
        class="itemHistory"
        *ngFor="let recentSearchText of recentUserSearch.recentSearchText"
        (click)="goToSearch($event, recentSearchText)"
      >
        <i class="fa-regular fa-clock"></i>
        <a (blur)="close($event)" (click)="close($event, true)">
          {{ recentSearchText }}</a
        >
      </li>
    </ul>
  </div>
  <!-- Quick Order -->
  <div
    *ngIf="quickOrder && showQuickOrder && results$ | async as result"
    class="results"
    id="quickOrder"
    role="listbox"
    (blur)="onBlur()"
  >
    <ul
      class="products"
      *ngIf="result?.products?.length === 0 && result?.message != null"
      tabindex="0"
    >
      <li>
        <a class="resultnot-found" (click)="onBlur()">
          {{ 'searchProduct.noResultsFound' | cxTranslate }}
        </a>
      </li>
    </ul>
    <ul class="products" *ngIf="result.products" tabindex="0">
      <li *ngFor="let product of result.products">
        <a>
          <div
            class="name"
            [innerHTML]="product.nameHtml"
            (click)="quickOrderClick(product)"
          ></div>
        </a>
      </li>
    </ul>
  </div>
</ng-container>
