import { Component, EventEmitter, Output } from '@angular/core';
import { QuickOrderComponent } from '../quick-order/quick-order/quick-order.component';

@Component({
  selector: 'app-product-search',
  templateUrl: './product-search.component.html',
})
export class ProductSearchComponent extends QuickOrderComponent {
  @Output() selectedProductEvent = new EventEmitter<any>();
  onProductSelected(product) {
    if (product?.code !== undefined) {
      this.productInfo = product;
      this.quickOrderDetail = true;
      this.selectedProductEvent.emit(this.productInfo?.code);
    } else if (product?.code === undefined) {
      this.quickOrderDetail = false;
    }
  }
}
