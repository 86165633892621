import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ICON_TYPE } from '@spartacus/storefront';
import { UserAccountFacade } from '@spartacus/user/account/root';
import * as html2pdf from 'html2pdf.js';
import * as moment from 'moment';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { enGbLocale } from 'ngx-bootstrap/locale';
import { OrderFilter } from 'src/app/shared/models/order-filter.model';
import { DataLayerService } from 'src/app/shared/services/data-layer.service';
import { CommonUtils } from '../../shared/utils/common.utils';
import { OrderHistoryService } from '../order-history.service';
@Component({
  selector: 'app-list-orders',
  templateUrl: './list-orders.component.html',
  styleUrls: ['./list-orders.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListOrdersComponent implements OnInit, OnDestroy {
  ordersList = [];
  totalOrders: number = 0;
  orders = [];
  userId;
  pageLength = '18 per page';
  timeFrameList = ['All', '30 Days', '6 Months', '1 Year'];
  pageList = [this.pageLength, '36 per page', '72 per page', 'All'];
  timeFrame = '6 Months';
  fromDate: any;
  toDate: any;
  poNumber: any;
  order: any;
  invoice: any;
  dateError = false;
  selectedTimeFrame = '6 Months';
  pageSizeperPage = this.pageLength;
  pageSize = 18;
  curPage = 1;
  end: number;
  @ViewChild('facetsRwd', { static: false }) private readonly facetsRwd;
  singleDateError = false;
  facetsList: any = [{ type: 'timeFrame', value: '6 Months' }];
  filters: OrderFilter = {
    fields: 'DEFAULT',
    timeFrame: '6',
    currentPage: this.curPage - 1,
    pageSize: this.pageSize,
  };
  maxSelectionDate = new Date();
  dateFormat = 'MM/dd/yyyy';
  userDataSubscription: any;
  userInfo: any;
  FilteredData: any;
  allOrdersSubscription: any;
  xlsDownloaded: boolean = false;
  productCode: number;

  constructor(
    private readonly service: OrderHistoryService,
    private readonly user: UserAccountFacade,
    private readonly cd: ChangeDetectorRef,
    private readonly modalService: NgbModal,
    private readonly localeService: BsLocaleService,
    private readonly dataLayerService: DataLayerService
  ) {
    enGbLocale.weekdaysShort = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
    enGbLocale.week.dow = 0;
    defineLocale('en-gb', enGbLocale);
    this.localeService.use('en-gb');
  }

  ngOnInit() {
    this.end = this.pageSize;
    this.getOrderList();
  }

  refine(): void {
    this.modalService.open(this.facetsRwd);
  }

  hide(): void {
    this.modalService.dismissAll(this.facetsRwd);
  }

  generateInvoicePDF(invoiceNo) {
    this.service.downloadPdf(invoiceNo);
  }

  getOrderList() {
    this.userInfo = this.user.get().subscribe((userData) => {
      this.userId = userData;
      this.filters = this.getfilterQuery();
      if (userData) {
        const params = { baseSiteId: 'ss', userId: this.userId.userName };
        this.service.getOrderHistory(params, this.filters);
        this.userDataSubscription = this.service.userData$.subscribe((res) => {
          if (res !== null) {
            try {
              this.ordersList = res?.orders;
              this.totalOrders = res?.pagination?.totalResults;
              this.cd.detectChanges();
              this.formatOrders();
              if (this.pageSize > this.totalOrders) {
                this.end = this.totalOrders;
              } else if (this.pageSize < this.totalOrders) {
                this.end = this.pageSize * this.curPage;
                if (this.end > this.totalOrders) {
                  this.end = this.totalOrders;
                }
              }

              this.ordersList = CommonUtils.sortArr(
                'placed',
                this.orders,
                'date',
                -1
              );
              res = null;
              this.cd.detectChanges();
            } catch {}
          }
        });
      }
    });
  }

  ngOnDestroy() {
    this.userDataSubscription?.unsubscribe();
    this.userInfo?.unsubscribe();
    this.allOrdersSubscription?.unsubscribe();
  }

  formatOrders() {
    this.orders = [];
    this.ordersList.forEach((element) => {
      const dateOnly = element.placed.split('T');
      const data = {
        code: element.code,
        guid: element.guid,
        invoices: element.invoices,
        sapSystemType:
          element && element.sapSystemType ? element.sapSystemType : '',
        placed: element.placed,
        placedDate: dateOnly[0],
        purchaseOrderNumber: element.purchaseOrderNumber,
        shipToId: element.shipToId,
        shipToName: element.shipToName,
        status: element.status,
        statusDisplay: element.statusDisplay,
        total: element.total,
        tracking: element?.tracking,
      };
      this.orders.push(data);
    });
  }

  navigateToTracking(data, url, event) {
    let a = url.split('<TRACKN>');
    event.target.rel = a[0] + data;
  }

  changePageSize() {
    this.curPage = 1;
    if (this.pageSizeperPage === this.pageLength) {
      this.pageSize = 18;
    }
    if (this.pageSizeperPage === '36 per page') {
      this.pageSize = 36;
    }
    if (this.pageSizeperPage === '72 per page') {
      this.pageSize = 72;
    }
    if (this.pageSizeperPage === 'All') {
      this.pageSize = this.totalOrders;
    }
    if (this.pageSize < this.totalOrders) {
      this.end = this.pageSize;
    } else {
      this.end = this.totalOrders;
    }
  }

  getCurrentPage(type) {
    this.curPage = type == 'prev' ? this.curPage - 1 : this.curPage + 1;
    this.cd.detectChanges();
  }

  numberOfPages() {
    if (this.ordersList && this.totalOrders && this.pageSize > 0) {
      return Math.ceil(this.totalOrders / this.pageSize);
    } else {
      return 1;
    }
  }

  iconTypes = ICON_TYPE;

  sortColumn(event, fieldName, dataList, type): any {
    CommonUtils.onSortClick(event, fieldName, dataList, type); // Column Sorting
  }

  removeFacet(filter) {
    this.facetsList.forEach((element, index) => {
      if (element === filter) {
        this.facetsList.splice(index, 1);
        if (filter.type === 'date') {
          this.fromDate = undefined;
          this.toDate = undefined;
        }
        if (filter.type === 'poNumber') {
          this.poNumber = undefined;
        }
        if (filter.type === 'orderNumber') {
          this.order = undefined;
        }
        if (filter.type === 'invoiceNumber') {
          this.invoice = undefined;
        }
        if (filter.type === 'timeFrame') {
          this.timeFrame = 'All';
        }
        if (filter.type === 'productCode') {
          this.productCode = undefined;
          this.service.alterorderFilterProduct('');
        }

        this.curPage = 1;
        this.getOrderList();
        this.cd.detectChanges();
      }
    });
  }

  applyFilter(type?: string) {
    window.scrollTo(0, 0);
    if (this.fromDate && this.toDate) {
      if (this.fromDate.getTime() > this.toDate.getTime()) {
        this.dateError = true;
        return false;
      } else {
        this.dateError = false;
      }
    }
    const params = { baseSiteId: 'ss', userId: this.userId.userName };
    this.filters = this.getfilterQuery();
    this.showFacets(JSON.stringify(this.filters));
    this.filters.currentPage = 0;
    this.curPage = 1;
    this.service.getOrderHistory(params, this.filters);
    this.userDataSubscription = this.service.userData$.subscribe((res) => {
      if (res !== null) {
        this.ordersList = res?.orders;
        this.totalOrders = res?.pagination?.totalResults;
        this.cd.detectChanges();
        this.formatOrders();
        this.ordersList = CommonUtils.sortArr(
          'placed',
          this.orders,
          'normal',
          -1
        );
        this.cd.detectChanges();
        this.selectedTimeFrame = this.timeFrame;
        if (type === 'mobile') {
          this.hide();
        }
      }
    });
    return true;
  }

  showFacets(data) {
    const facets = JSON.parse(data);
    delete facets.fields;
    delete facets.currentPage;
    delete facets.pageSize;
    if (facets.fromDate) {
      facets.date = `${moment(this.fromDate).format(
        this.dateFormat.toUpperCase()
      )} - ${moment(this.toDate).format(this.dateFormat.toUpperCase())}`;
      delete facets.fromDate;
      delete facets.toDate;
    }
    if (facets.timeFrame && facets.timeFrame != '') {
      facets.timeFrame = this.timeFrame;
    } else {
      facets.timeFrame = 'All';
    }
    this.facetsList = Object.entries(facets).map(([type, value]) => ({
      type,
      value,
    }));
  }

  getTimeFrame(): string {
    let time: string = '';
    if (this.timeFrame === 'ALL') {
      time = this.timeFrame;
    }
    if (this.timeFrame === '30 Days') {
      time = '30';
    }
    if (this.timeFrame === '6 Months') {
      time = '6';
    }
    if (this.timeFrame === '1 Year') {
      time = '1';
    }
    return time;
  }

  getfilterQuery(): OrderFilter {
    let data: OrderFilter = <any>{
      fields: 'DEFAULT',
      currentPage: this.curPage - 1,
      pageSize: this.pageSize,
    };
    if (this.invoice) {
      data.invoiceNumber = this.invoice;
    }
    if (this.order) {
      data.orderNumber = this.order;
    }
    if (this.poNumber) {
      data.poNumber = this.poNumber;
    }
    if (this.timeFrame && !this.fromDate && !this.toDate) {
      data.timeFrame = this.getTimeFrame();
    }
    if (this.fromDate && this.toDate) {
      if (this.timeFrame === 'All' && data.timeFrame) {
        delete data.timeFrame;
      }
      data.fromDate = moment(this.fromDate).format('DD-MM-YYYY');
      data.toDate = moment(this.toDate).format('DD-MM-YYYY');
    } else if (
      (!this.fromDate && this.toDate) ||
      (this.fromDate && !this.toDate)
    ) {
      this.singleDateError = true;
    }
    if (this.productCode) {
      data.productCode = this.productCode;
    }
    return data;
  }

  validateDate() {
    this.timeFrame = this.fromDate || this.toDate ? 'All' : this.timeFrame;
    if (this.fromDate && this.toDate) {
      this.singleDateError = false;
      if (this.fromDate.getTime() > this.toDate.getTime()) {
        this.dateError = true;
      } else {
        this.dateError = false;
      }
    }
  }

  clearDateRange() {
    this.fromDate = undefined;
    this.toDate = undefined;
  }

  generateSavePdf() {
    const options = {
      margin: [10, 10, 10, 10],
      filename: 'Order History.pdf',
      image: { type: 'jpeg' },
      html2canvas: {},
      jsPDF: { orientation: 'portrait' },
    };

    const element: Element = document.getElementById('ordersTable');
    html2pdf().from(element).set(options).save();
    this.dataLayerService.downloadDataLayer(
      options?.filename,
      'pdf',
      'Order History'
    );
  }

  generateXls(allOrders) {
    const list = JSON.parse(JSON.stringify(allOrders));
    const orders = [];
    list.forEach((element) => {
      const dateOnly = element.placed.split('T');
      const invoices = Array.prototype.map
        .call(element.invoices, function (item) {
          return item.invoiceNumber;
        })
        .join(',');
      const trackingNumber = Array.prototype.map
        .call(element.tracking, function (item) {
          if (item.carrier && item.carrier !== 'YB') {
            return item.trackingNumber;
          }
        })
        .filter((item) => item !== undefined)
        .join(',');
      orders.push({
        Date: moment(dateOnly[0]).format(this.dateFormat),
        Type: element.sapSystemType,
        'Order #': element.guid,
        Status: element.statusDisplay,
        'PO #': element.purchaseOrderNumber,
        'Invoice #': invoices,
        'Tracking #': trackingNumber,
        'Ship to': `${element.shipToName} ${element.shipToId}`,
      });
    });
    this.service.downloadXls(orders, 'OrderHistory');
    this.xlsDownloaded = true;
  }

  async generateSaveXls() {
    let xlsDownloadFilter: any = JSON.stringify(this.filters);
    this.xlsDownloaded = false;
    xlsDownloadFilter = JSON.parse(xlsDownloadFilter);
    xlsDownloadFilter.currentPage = 0;
    xlsDownloadFilter.pageSize = 1000;
    const params = { baseSiteId: 'ss', userId: this.userId.userName };
    this.service.getAllOrders(params, xlsDownloadFilter);
    this.allOrdersSubscription = this.service.allOrders$.subscribe((res) => {
      if (res !== null) {
        if (!this.xlsDownloaded) {
          this.generateXls(res?.orders);
          this.cd.detectChanges();
          this.service.allOrders?.next(null);
        }
      }
    });
  }

  clearFilters() {
    this.facetsList = [];
    this.filters = {
      fields: 'DEFAULT',
      timeFrame: 'All',
      currentPage: 0,
      pageSize: this.pageSize,
    };
    this.curPage = 1;
    this.fromDate = undefined;
    this.toDate = undefined;
    this.order = undefined;
    this.invoice = undefined;
    this.poNumber = undefined;
    this.timeFrame = 'All';
    this.productCode = undefined;
    this.getOrderList();
    this.service.alterorderFilterProduct('');
  }
  getPONumber(purchaseOrderNumber: any) {
    if (purchaseOrderNumber) {
      const poNumber = '';
      const searchString = 'WWW:';
      const regex = new RegExp(searchString, 'i');
      if (regex.test(purchaseOrderNumber)) {
        return poNumber;
      } else {
        return purchaseOrderNumber;
      }
    }
  }

  productSelected(event): void {
    this.productCode = event;
    this.service.alterorderFilterProduct(event);
  }
}
