<aside
  class="col-12 col-xs-12 col-sm-5 col-md-4 right-navigation float-right my-acc-aside"
>
  <div class="c-widget d-none d-sm-flex">
    <h3 class="c-widget-title c-heading c-heading-h3">
      {{ 'accountOverview.myAccount_label' | cxTranslate }}
    </h3>
    <div class="c-widget-divider"></div>
    <div class="d-flex justify-content-between">
      <div class="c-widget--my-account-description">
        {{ 'accountOverview.account_label' | cxTranslate }} &nbsp;
        <strong>{{ accountNumber }}</strong>
      </div>
      <div>
        <a
          class="c-link"
          data-di-id="di-id-9b8eeaed-3b366785"
          href="javascript:;"
          (click)="navigateToAccountDetails()"
        >
          {{ 'accountOverview.view_label' | cxTranslate }}
        </a>
      </div>
    </div>
  </div>

  <app-order-products></app-order-products>
  <section class="d-none d-sm-none banner-Align"></section>
</aside>
