<div class="checkout-modal">
  <div class="close" (click)="close()">
    <i
      class="fa-solid fa-x c-icon-primary c-icon-primary-sm c-icon-round-hover mr-3 mt-4"
    ></i>
  </div>
  <div class="modal-header relative">
    <h2 class="c-heading-36 font-bold u-border-bottom-grey pb-4 w-100">
      {{ 'addToCartItems.prodDetails' | cxTranslate }}
    </h2>
  </div>
  <div class="modal-body miniCart-popover">
    <div class="row row-20 product-details pb-3" id="leftCont-rightNav">
      <section class="row row-20">
        <section class="container-pdp">
          <section
            class="col-xs-12 col-sm-8 col-md-8 left-section pb-2 float-left"
          >
            <h1
              class="c-heading c-heading-h2 mb-3"
              [innerHTML]="productDetails?.product?.name"
            ></h1>
            <p class="text-fixed-medium" [innerHTML]="description"></p>
            <br />
            <section class="text-fixed-medium">
              <p *ngIf="productDetails?.product?.batchInfo?.batchNumber">
                <b>{{ 'plp.batchNumber' | cxTranslate }} </b>
                <span>{{
                  productDetails?.product?.batchInfo?.batchNumber
                }}</span>
              </p>
              <p *ngIf="productDetails?.product?.batchInfo?.batchNumber">
                <b>{{ 'plp.expiryDate' | cxTranslate }} </b>
                <span>{{
                  productDetails?.product?.batchInfo?.expiryDate
                }}</span>
              </p>
              <p *ngIf="productDetails?.product?.batchInfo?.batchNumber">
                {{ 'plp.batchInfo' | cxTranslate }}
              </p>
              <p>
                <b>{{ 'plp.productNumber' | cxTranslate }} </b>
                <span class="desc-details">{{
                  productDetails?.product?.sapEan
                }}</span>
              </p>
              <p *ngIf="productDetails?.product?.actualPotency">
                <b>{{ 'plp.actualPotency' | cxTranslate }} </b>
                <span class="desc-details">{{
                  productDetails?.product?.actualPotency
                }}</span>
              </p>
              <hr class="line" />
              <section
                class="sap-allocation-area"
                *ngIf="
                  !productDetails?.product?.discontinued &&
                  productDetails?.product?.available &&
                  !productDetails?.product?.flu
                "
              >
                <section
                  *ngIf="productDetails?.product?.sapAllocation === true"
                >
                  <p class="text-danger">
                    <b>{{ 'plp.productAllocation' | cxTranslate }}</b>
                  </p>
                  <p>
                    {{ 'plp.allocationDetails' | cxTranslate }}
                  </p>
                </section>
              </section>
              <section class="third-col">
                <section
                  class="row unit-calc"
                  *ngIf="unitSection === 'NoUnits'"
                >
                  <section
                    class="col-md-4 col-xs-6 bottom-spacing price-area"
                    *ngIf="!productDetails?.product?.flu"
                  >
                    <div
                      *ngIf="
                        productDetails?.product?.actualPotency;
                        else notActualPotency
                      "
                    >
                      <b>{{ 'plp.unitPrice' | cxTranslate }}</b>
                    </div>
                    <ng-template #notActualPotency>
                      <b>{{ 'plp.yourPrice' | cxTranslate }}</b>
                    </ng-template>
                    <section class="number">
                      <span
                        *ngIf="
                          productDetails?.basePrice == undefined ||
                          productDetails?.basePrice?.value === undefined
                        "
                        >-</span
                      >
                      <span
                        *ngIf="
                          productDetails?.basePrice &&
                          productDetails?.basePrice?.value !== undefined
                        "
                      >
                        <span>{{
                          productDetails?.basePrice?.value | currency
                        }}</span>
                      </span>
                    </section>
                  </section>
                </section>
                <section
                  class="row unit-calc d-flex align-items-center"
                  *ngIf="
                    unitSection === 'Update' || unitSection === 'AddToCart'
                  "
                >
                  <!-- price - quantity- button -->
                  <section
                    class="col-md-4 col-xs-6 bottom-spacing price-area"
                    *ngIf="!productDetails?.product?.flu"
                  >
                    <h5
                      class="mb-2 font-weight-bold color-grey-dark"
                      *ngIf="productDetails?.product?.actualPotency"
                    ></h5>
                    <h5
                      class="mb-2 font-weight-bold color-grey-dark"
                      *ngIf="
                        productDetails?.product?.actualPotency;
                        else notActualPotency
                      "
                    >
                      {{ 'plp.unitPrice' | cxTranslate }}
                    </h5>
                    <ng-template #notActualPotency>
                      <h5 class="mb-2 font-weight-bold color-grey-dark">
                        {{ 'plp.yourPrice' | cxTranslate }}
                      </h5>
                    </ng-template>
                    <p class="c-heading c-heading-h3">
                      <span
                        *ngIf="
                          productDetails?.basePrice == undefined ||
                          productDetails?.basePrice?.value === undefined
                        "
                        >-</span
                      >
                      <span
                        *ngIf="
                          productDetails?.basePrice &&
                          productDetails?.basePrice?.value !== undefined
                        "
                      >
                        <span>{{
                          productDetails?.basePrice?.value | currency
                        }}</span>
                      </span>
                    </p>
                  </section>
                  <section class="col-md-3 col-xs-6 bottom-spacing units-area">
                    <section *ngIf="!productDetails?.product?.discontinued">
                      <section *ngIf="productDetails?.product?.available">
                        <h5
                          class="mb-2 text-center font-weight-bold color-grey-dark"
                        >
                          {{ 'plp.units' | cxTranslate }}
                        </h5>

                        <span
                          class="disabled"
                          aria-hidden="true"
                          id="{{
                            'minusIcon' +
                              productDetails?.product?.code +
                              'modal'
                          }}"
                          (click)="
                            performQuantityComputation(
                              2,
                              productDetails?.product?.code + 'modal',
                              productDetails?.product?.quantityPerUnit
                            )
                          "
                        >
                          <img
                            src="../../assets/icons/less.svg"
                            id="{{
                              'unitMinus' +
                                productDetails?.product?.code +
                                'modal'
                            }}"
                            [ngClass]="{
                              'disabled-img': productDetails?.quantity === 0
                            }"
                            alt=""
                          />
                          <img
                            src="../../assets/icons/less-disable.svg"
                            id="{{
                              'unitMinusDisable' +
                                productDetails?.product?.code +
                                'modal'
                            }}"
                            alt=""
                            [ngClass]="{
                              'disabled-img': productDetails?.quantity > 0
                            }"
                          />
                        </span>
                        <input
                          type="number"
                          class="prod-quantity text-center"
                          value="{{ productDetails?.quantity }}"
                          onkeypress="return /[0-9a-zA-Z]/i.test(event.key)"
                          (keyup)="
                            performQuantityComputation(
                              null,
                              productDetails?.product?.code + 'modal',
                              productDetails?.product?.quantityPerUnit
                            )
                          "
                          id="{{
                            'prodQuantity' +
                              productDetails?.product?.code +
                              'modal'
                          }}"
                          min="1"
                          max="9999"
                        />
                        <span
                          class=""
                          aria-hidden="true"
                          id="{{
                            'plusIcon' + productDetails?.product?.code + 'modal'
                          }}"
                          (click)="
                            performQuantityComputation(
                              1,
                              productDetails?.product?.code + 'modal',
                              productDetails?.product?.quantityPerUnit
                            )
                          "
                        >
                          <img
                            src="../../assets/icons/more.svg"
                            id="{{
                              'unitPlus' +
                                productDetails?.product?.code +
                                'modal'
                            }}"
                            alt=""
                          />
                          <img
                            src="../../assets/icons/more-disable.svg"
                            id="{{
                              'unitPlusDisable' +
                                productDetails?.product?.code +
                                'modal'
                            }}"
                            class="disabled-img"
                            alt=""
                          />
                        </span>
                      </section>
                    </section>
                  </section>

                  <section
                    class="mt-4 mt-md-0 col-md-5 col-sm-4 col-xs-12 d-flex justify-content-center bottom-spacing button-area"
                  >
                    <button
                      *ngIf="unitSection === 'Update'; else addToCart"
                      class="c-button c-button--primary"
                      data-di-id="di-id-2fea5a72-b2e23002"
                      [ngbPopover]="popTitleSuccess"
                      placement="bottom"
                      triggers="manual"
                      #p2="ngbPopover"
                      placement="bottom"
                      popoverClass="updatecart-msg"
                      (click)="
                        updateCart(
                          productDetails?.product?.code + 'modal',
                          p2,
                          productDetails
                        )
                      "
                    >
                      {{ 'addToCartItems.updateCart' | cxTranslate }}
                    </button>

                    <section class="added-success-container">
                      <ng-template #popTitleSuccess>
                        <div class="cart-value-added">
                          <img
                            src="../../../assets/icons/confirmation-positive-green.png"
                            class="positive-green-icon"
                            alt="success"
                          />
                          <span class="confirmation-itemsadded"
                            >{{ itemAddedQuantity }}
                            {{
                              'addToCartItems.addUpdateSuccess' | cxTranslate
                            }}</span
                          >
                        </div>
                      </ng-template>
                      <ng-template #popTitleAddSuccess>
                        <div class="cart-value-added">
                          <img
                            src="../../../assets/icons/confirmation-positive-green.png"
                            class="positive-green-icon"
                            alt="success"
                          />
                          <span class="confirmation-itemsadded"
                            >{{ itemAddedQuantity }}
                            {{
                              'addToCartItems.addEntrySuccess' | cxTranslate
                            }}</span
                          >
                        </div>
                      </ng-template>
                    </section>

                    <ng-template #addToCart>
                      <button
                        class="c-button c-button--primary"
                        data-di-id="di-id-2fea5a72-b2e23002"
                        [ngbPopover]="popTitleAddSuccess"
                        placement="bottom"
                        triggers="manual"
                        #p3="ngbPopover"
                        placement="bottom"
                        popoverClass="addtocart-msg"
                        (click)="
                          addToSessionCart(
                            productDetails?.product?.code + 'modal',
                            productDetails?.product?.code,
                            p3
                          )
                        "
                      >
                        {{ 'addToCartItems.addCart' | cxTranslate }}
                      </button>
                    </ng-template>
                  </section>
                  <section
                    class="bottom-spacing availability-text"
                    *ngIf="
                      !productDetails?.product?.discontinued &&
                      !productDetails?.product?.sapAllocation &&
                      !productDetails?.product?.flu
                    "
                  ></section>
                </section>
                <section
                  *ngIf="
                    !productDetails?.product?.discontinued &&
                    productDetails?.product?.available
                  "
                  class="products-accordion clear-fix pt2"
                >
                  <section class="panel-group">
                    <section
                      class="panel panel-default"
                      *ngIf="volumePrices?.length > 0"
                    >
                      <section class="panel-heading panel-endborder">
                        <section class="first-level-acc text-fixed-medium">
                          <label
                            ><img
                              src="../../assets/icons/price-master.svg"
                              class="accordion-img"
                              alt=""
                            />
                            {{ 'plp.volumePricing' | cxTranslate }}</label
                          >
                          <span
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#pdp-list1"
                            class="acc-plus-icon fa"
                            aria-expanded="panelVolumeExpanded"
                            (click)="panelVolumeExpanded = !panelVolumeExpanded"
                            [ngClass]="
                              panelVolumeExpanded
                                ? 'fa-minus'
                                : 'fa-plus collapsed'
                            "
                          ></span>
                        </section>
                      </section>
                      <section
                        id="pdp-list1"
                        class="panel-collapse collapse"
                        aria-expanded="panelVolumeExpanded"
                        (click)="panelVolumeExpanded = !panelVolumeExpanded"
                        [ngClass]="panelVolumeExpanded ? 'in' : ''"
                      >
                        <section class="panel-body">
                          <section id="vol-price-pdp">
                            <table
                              aria-describedby="pdpTable"
                              class="c-table c-table--branded"
                            >
                              <tbody>
                                <tr
                                  *ngFor="
                                    let prices of volumePrices;
                                    let i = index
                                  "
                                >
                                  <td>
                                    {{ prices?.minQuantity }} -
                                    {{ prices?.maxQuantity }}
                                    {{ 'plp.units' | cxTranslate }}:
                                  </td>
                                  <td>{{ prices?.value | currency }}</td>
                                  <td>
                                    <span *ngIf="i > 0"
                                      ><span
                                        class="text-success text-fixed-legal text-uppercase"
                                        >{{ 'plp.save' | cxTranslate }}</span
                                      >&nbsp;
                                      <span class="font-bold">{{
                                        volumePrices[0].value -
                                          volumePrices[i].value | currency
                                      }}</span>
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </section>
                        </section>
                      </section>
                    </section>
                  </section>
                </section>
              </section>

              <!-- The End -->
              <section>
                <section class="products-accordion clear-fix pt2">
                  <section class="panel-group">
                    <!-- update2 -->
                    <section
                      class="panel panel-default"
                      *ngIf="productDetails?.product?.productUpdates"
                    >
                      <section class="panel-heading">
                        <section class="first-level-acc">
                          <label
                            ><img
                              src="../../assets/icons/info.png "
                              class="accordion-img"
                              alt=" "
                            />{{ 'plp.productUpdate' | cxTranslate }}
                          </label>
                          <span
                            data-toggle="collapse "
                            data-parent="#accordion "
                            href="#pdp-list3 "
                            class="acc-plus-icon fa"
                            aria-expanded="panelProductExpanded "
                            (click)="
                              panelProductExpanded = !panelProductExpanded
                            "
                            [ngClass]="
                              panelProductExpanded
                                ? 'fa-minus'
                                : 'fa-plus collapsed'
                            "
                          ></span>
                        </section>
                      </section>
                      <section
                        id="pdp-list3 "
                        class="panel-collapse collapse"
                        aria-expanded="panelProductExpanded "
                        (click)="panelProductExpanded = !panelProductExpanded"
                        [ngClass]="panelProductExpanded ? 'in' : ''"
                      >
                        <section class="panel-body productupdate-p">
                          {{ productDetails?.product?.productUpdates }}
                        </section>
                      </section>
                    </section>
                  </section>
                </section>
              </section>
            </section>
          </section>

          <aside
            aria-label="Product Details"
            id="prodImg"
            class="col-xs-12 col-sm-4 col-md-4 right-navigation float-right"
          >
            <a
              *ngIf="
                productDetails?.product?.images != undefined ||
                productDetails?.product?.images?.length > 0
              "
            >
              <img
                class="image-product"
                src="{{ images?.PRIMARY?.url }}"
                alt=""
              />
            </a>
            <a
              *ngIf="
                productDetails?.product?.images === undefined ||
                productDetails?.product?.images?.length === 0
              "
            >
              <img
                class="image-set-in-card product-img"
                [src]="fallbackImage"
                alt=""
              />
            </a>
          </aside>

          <aside
            aria-label="Product Details Pdf "
            class="col-sm-3 right-navigation float-left resources-section"
            *ngIf="monographLink"
          >
            <section>
              <section class="prod-detail-pdfs">
                <p class="pdfs-title">
                  {{ 'plp.resources' | cxTranslate }}
                </p>
                <p class="pdfs-list">
                  <img
                    class="pdf-icon"
                    src=" ../../assets/icons/pdf.png "
                    alt=" "
                  />
                  <a
                    alt=" "
                    [href]="monographLink"
                    target=" _blank "
                    class="file-link blue"
                    >{{ 'plp.monograph' | cxTranslate }}</a
                  >
                </p>
              </section>
            </section>
          </aside>
        </section>
      </section>
    </div>
  </div>
</div>
