import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '@spartacus/core';
import { Subscription } from 'rxjs';
import { CustomAddToCartService } from 'src/app/custom-add-to-cart/custom-add-to-cart.service';

@Component({
  selector: 'app-logout-modal',
  templateUrl: './logout-modal.html',
})
export class LogoutModalComponent implements OnInit {
  cartHasItem: boolean = false;
  cartSub: Subscription;

  constructor(
    private readonly modalService: NgbModal,
    private readonly authService: AuthService,
    private readonly addToCartService: CustomAddToCartService
  ) {}
  ngOnInit(): void {
    this.cartSub = this.addToCartService.cartItems$.subscribe((res) => {
      if (res) {
        res?.childCarts.length > 0
          ? (this.cartHasItem = true)
          : (this.cartHasItem = false);
        this.cartSub?.unsubscribe();
      }
    });
  }

  cancelModal() {
    this.modalService.dismissAll();
  }
  logout(): void {
    localStorage.removeItem('isShowFlag');
    sessionStorage.removeItem('userAccountId');
    this.authService.logout();
    this.modalService.dismissAll();
  }
}
