import { OccConfig, OccEndpoints } from '@spartacus/core';

export const customOccProductConfig: OccConfig = {
  backend: {
    occ: {
      endpoints: {
        productSearch:
          'users/${userId}/products/search?fields=products(DEFAULT,images(DEFAULT)),facets,breadcrumbs,pagination(DEFAULT),sorts(DEFAULT),freeTextSearch,currentQuery',
        productSearchNoprice:
          'users/${userId}/products/search/noprice?fields=products(DEFAULT,images(DEFAULT)),facets,breadcrumbs,pagination(DEFAULT),sorts(DEFAULT),freeTextSearch,currentQuery',
        productSuggestions: 'autocomplete',
        product: {
          default:
            'users/${userId}/products/${productCode}?fields=DEFAULT,categories(DEFAULT)',
          details:
            'users/${userId}/products/${productCode}?fields=DEFAULT,categories(BASIC,name,url)',
        },
      } as OccEndpoints,
    },
  },
};
