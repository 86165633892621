import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { I18nModule } from '@spartacus/core';
import { SearchBoxModule } from 'src/app/search-box/search-box.module';
import { ProductSearchComponent } from './product-search.component';

@NgModule({
  declarations: [ProductSearchComponent],
  imports: [CommonModule, SearchBoxModule, NgbModule, I18nModule],
  exports: [ProductSearchComponent],
})
export class ProductSearchModule {}
