import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbPopover, NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';
import { RoutingService } from '@spartacus/core';
import {
  SearchBoxComponentService,
  SearchBoxConfig,
} from '@spartacus/storefront';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Subscription } from 'rxjs';
import { CustomAddToCartService } from '../../../../custom-add-to-cart/custom-add-to-cart.service';
import { CustomProdPgService } from '../../../../custom-prod-pg/custom-prod-pg.service';
import { ProductUtils } from '../../../utils/productUtils';
import { CustomPdpService } from 'src/app/custom-pdp/custom-pdp.service';

@Component({
  selector: 'app-quick-order',
  templateUrl: './quick-order.component.html',
  styleUrls: ['./quick-order.component.scss'],
})
export class QuickOrderComponent implements OnInit, OnDestroy {
  @Output() selectedProductEvent = new EventEmitter<any>();
  quickOrderDetail = false;
  clearSearch = false;
  productInfo: any;
  buttonName: string;
  itemAddedQuantity: any;
  userDetails: any;
  cartId: any;
  cartItems: any;
  QUICKORDER_SEARCH_BOX_CONFIG: SearchBoxConfig = {
    minCharactersBeforeRequest: 3,
    displayProducts: true,
    displaySuggestions: false,
    displayProductImages: true,
  };
  clearSearchValue = false;
  userDataSub: Subscription;
  allCartsSub: Subscription;
  createdCartSub: Subscription;
  updatedCartSub: Subscription;
  updatedCartDetailsSub: Subscription;
  @ViewChild('p2') public successPopover: NgbPopover;
  private productSub: Subscription;
  priceResAwait: boolean = false;

  constructor(
    private readonly user: UserAccountFacade,
    private readonly config: NgbPopoverConfig,
    private readonly route: RoutingService,
    private readonly plpService: CustomProdPgService,
    protected searchBoxComponentService: SearchBoxComponentService,
    private readonly addToCartService: CustomAddToCartService,
    private pdpService: CustomPdpService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    if (this.productInfo?.flu === true) {
      this.buttonName = 'Reserve now';
    } else {
      this.buttonName = 'Add to cart';
    }
    this.userDataSub = this.user.get().subscribe((res) => {
      this.userDetails = res;
      if (res !== undefined) {
        if (this.productInfo?.flu === true) {
          this.buttonName = 'Reserve now';
        } else {
          this.buttonName = 'Add to cart';
        }
      } else {
        this.buttonName = 'Login to order';
      }
    });
    this.allCartsSub = this.addToCartService.allCartItems$.subscribe((res) => {
      if (res) {
        this.cartItems = res?.carts;
        this.cartItems = this.cartItems.filter(
          (item) => item?.saveTime === undefined
        );
      }
    });
  }

  addToCart(idData, productCode): void {
    this.clearSearchValue = false;
    if (this.buttonName === 'Reserve now') {
      this.navToReservenow();
    } else if (this.buttonName === 'Add to cart') {
      const inputElement = document.getElementById(
        `prodQuantity${idData}`
      ) as HTMLInputElement;
      const params = {
        product: {
          code: productCode,
        },
        quickOrder: true,
        quantity: inputElement?.value,
      };
      this.itemAddedQuantity = inputElement?.value;
      if (this.cartItems && this.cartItems?.length === 0) {
        this.plpService.createCart(this.userDetails?.userName);
        this.createdCartSub = this.plpService.createdCart$.subscribe((res) => {
          if (res) {
            this.cartId = res?.code;
            this.addToCartService.getAllCarts(this.userDetails?.userName);
            this.plpService.updateCart(
              params,
              this.userDetails?.userName,
              this.cartId
            );
            this.updatedCartSub = this.plpService.cartDetailsUpdate$.subscribe(
              (resp) => {
                if (resp) {
                  this.successPopover.open();
                  setTimeout(() => this.successPopover.toggle(), 2500);
                }
              }
            );
          }
        });
      } else {
        if (this.cartId === undefined) {
          this.cartItems =
            this.cartItems && this.cartItems?.length > 0
              ? this.cartItems[this.cartItems?.length - 1]
              : [];
          this.cartId = this.cartItems?.code;
        }
        this.plpService.updateCart(
          params,
          this.userDetails?.userName,
          this.cartId
        );
        this.updatedCartDetailsSub =
          this.plpService.cartDetailsUpdate$.subscribe((res) => {
            if (res) {
              this.successPopover.open();
              setTimeout(() => this.successPopover.toggle(), 2500);
              this.clearSearch = true;
            }
          });
      }
    }
  }
  ngDoCheck() {
    if (!this.successPopover?.isOpen() && this.clearSearch) {
      this.quickOrderDetail = false;
      this.clearSearch = false;
      this.clearSearchValue = true;
    }
  }
  navToReservenow() {
    this.route.goByUrl('/influenza/category');
  }

  performQuantityComputation(operator, idData, quantityOfUnits): void {
    ProductUtils.alterQuantity(operator, idData, quantityOfUnits);
  }
  addtoFluCart() {
    this.route.goByUrl('/influenza/category');
  }
  onProductSelected(product) {
    if (product?.code !== undefined) {
      if (this.productSub) {
        this.productSub.unsubscribe();
      }
      this.productInfo = { ...product, price: undefined };
      this.priceResAwait = true;
      this.cdr.detectChanges();

      this.pdpService.getPDPCategory(product.code);

      this.productSub = this.pdpService.productCode$
      .subscribe((res) => {
        if (res !== null && res.code === product.code) {
          this.productInfo = { ...this.productInfo, price: res.price };
          this.priceResAwait = false;
          this.cdr.detectChanges();
        }
      });

      this.quickOrderDetail = true;
    } else if (product?.code === undefined) {
      this.quickOrderDetail = false;
    }
  }
  ngOnDestroy() {
    this.userDataSub?.unsubscribe();
    this.updatedCartDetailsSub?.unsubscribe();
    this.updatedCartSub?.unsubscribe();
    this.allCartsSub?.unsubscribe();
    this.createdCartSub?.unsubscribe();
    this.productSub?.unsubscribe();
  }
}
